exports.components = {
  "component---src-components-blog-detail-index-js": () => import("./../../../src/components/Blog/detail/index.js" /* webpackChunkName: "component---src-components-blog-detail-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/aboutUs/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-blog-company-index-js": () => import("./../../../src/pages/blog/company/index.js" /* webpackChunkName: "component---src-pages-blog-company-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-industry-index-js": () => import("./../../../src/pages/blog/industry/index.js" /* webpackChunkName: "component---src-pages-blog-industry-index-js" */),
  "component---src-pages-blog-preview-index-js": () => import("./../../../src/pages/blog/preview/index.js" /* webpackChunkName: "component---src-pages-blog-preview-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-id-card-index-js": () => import("./../../../src/pages/idCard/index.js" /* webpackChunkName: "component---src-pages-id-card-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lender-index-js": () => import("./../../../src/pages/lender/index.js" /* webpackChunkName: "component---src-pages-lender-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacyPolicy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-public-policy-index-js": () => import("./../../../src/pages/publicPolicy/index.js" /* webpackChunkName: "component---src-pages-public-policy-index-js" */),
  "component---src-pages-start-here-index-js": () => import("./../../../src/pages/startHere/index.js" /* webpackChunkName: "component---src-pages-start-here-index-js" */),
  "component---src-pages-terms-condition-index-js": () => import("./../../../src/pages/termsCondition/index.js" /* webpackChunkName: "component---src-pages-terms-condition-index-js" */)
}

