import "./static/global.css"
import ReactDOM from "react-dom"

require("typeface-roboto")
// gatsby-browser.js

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
